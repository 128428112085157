import React, { useEffect, useState } from "react";
import './app/globals.css';
import { NavBar } from "./Navbar";
import { Footer } from "./Footer";
import { useUser } from "@clerk/clerk-react";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
  } from "./@/components/ui/select"
  import LexicalEditor from './LexicalEditor';
  import { currently_generating, editorContentAtom } from './atoms'; 
import { useAtomValue } from "jotai";
import NotionAdderButton from './NotionAdderButton';
import PDFUploadButton from "./PDFUploadButton";
import DocxUploadButton from "./DocxUploadButton";
// page?.properties?.Name?.title?.[0]?.plain_text || page?.properties?.Title?.title?.[0]?.plain_text
// ^^ Subpages if needed, don't remove this


interface NotionPage {
    id: string;
    parent: {
      type: string;
    };
    properties: {
      title: {
        title: {
          text: {
            content: string;
          };
        }[];
      };
    };
  }

const PageSelect: React.FC = () => {
    const { user } = useUser();
    const [notionAuth, setNotionAuth] = useState('')
    const [userId, setUserId] = useState('')
    const [pages, setPages] = useState<any[]>([])
    const [selectedPage, setSelectedPage] = useState<NotionPage | null>(null);
    const [, setGetEditorText] = useState<() => string>(() => '');
    const editorContent = useAtomValue(editorContentAtom);
    const generatingState = useAtomValue(currently_generating);


      useEffect(() => {
        const retrieveUserNotionAuth = async () => {
            if (user) { // google clerk express - prob a middleware for express which grabs session cookie and converts to userID - really want the session cookie. Automatically gets sent with the body of each request so prob don't need to add to the body.
                // https://clerk.com/docs/backend-requests/handling/nodejs - from Ian - do this for safety
              try {
                const response = await fetch('http://localhost:5000/api/retrieve-user-notion-auth', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({ userId: user.id }),
                });

                const data = await response.json();
          
                if (!response.ok) {
                  throw new Error(data.error || 'Failed to retrieve Notion auth');
                }
          
                return data;
              } catch (error) {
                return null;
              }
            } else {
              return null;
            }
          };

        const fetchNotionAuth = async () => {
            if (user) {
                const notionAuth = await retrieveUserNotionAuth();
                setNotionAuth(notionAuth?.notion_auth)
                setUserId(user.id)
            }
        };

        fetchNotionAuth();
    }, [user]);

    useEffect(() => {
        const fetchPages = async () => {
            try {
                const response = await fetch('http://localhost:5000/api/list-pages', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ notionToken: notionAuth }),
                });
    
                const data = await response.json();
                setPages(data.results || []);
            } catch (error) {
                console.error("Error fetching pages:", error);
            }
        };

        if (notionAuth) {
            fetchPages();
        }
    }, [notionAuth]);

    const pageMap = pages.reduce((acc, page) => {
        if (page?.parent?.type === "workspace") {
            acc[page.id] = page;
        }
        return acc;
    }, {});

    const handleValueChange = (pageId: string) => {
        const selected = pageMap[pageId];
        setSelectedPage(selected);
    };

  return (
    <div className="flex flex-col min-h-screen bg-[#f9f9fd]">
            <NavBar />
            <div className="p-4 flex justify-center items-center flex-col">
                <h1 className="text-2xl font-bold">Generate a timeline with your content!</h1>
                <div className="py-4 grid grid-cols-2 gap-4 content-start">
                    <PDFUploadButton/>
                    <DocxUploadButton/>
                </div>
                <div className="w-full pb-4 flex justify-center items-center flex-col">
    {pages.length > 0 ? (
        <>
        <div className="w-full pb-4 flex justify-center items-center space-x-4">
        <Select onValueChange={handleValueChange}>
            <SelectTrigger className="w-full max-w-md">
                <SelectValue placeholder="Select a page" />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    <SelectLabel>Notion Workspace Pages</SelectLabel>
                    {pages.map((page, index) => (
                        page?.parent?.type === "workspace" && (
                            <SelectItem key={index} value={page.id}>
                                {(page?.properties?.title?.title?.[0]?.text?.content || page?.title?.[0]?.text?.content)}
                            </SelectItem>
                        )
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
        {generatingState === 'not_generating' && (
            <div className="flex items-center h-full space-x-2">
            <p className="text-gray-500">Not currently generating</p>
            </div>
        )}
        {generatingState === 'generating' && (
            <div className="flex items-center h-full space-x-2">
            <p className="text-gray-500">Generating</p>
                    <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-amber-300"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
            />
            <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
            />
        </svg>
            </div>
        )}
        {generatingState === 'error' && (
            <div className="flex items-center h-full space-x-2">
            <p className="text-gray-500">Error Generating</p>
            </div>
        )}
        {generatingState === 'success' && (
            <div className="flex items-center h-full space-x-2">
            <p className="text-gray-500">Successful Generation!</p>
            </div>
        )}
        </div>
    </>
    ) : (
        <div className="flex items-center justify-center h-full space-x-2">
  <p className="text-gray-500">No pages found or loading...</p>
  <svg
    aria-hidden="true"
    className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-amber-300"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
      fill="currentColor"
    />
    <path
      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
      fill="currentFill"
    />
  </svg>
</div>
        
        
    )}
</div>
                <LexicalEditor setGetEditorText={setGetEditorText} />
                
            </div>
            <NotionAdderButton editorContent={editorContent} notionAuth={notionAuth} userId={userId} selectedPageId={selectedPage?.id || ""}/>
            <Footer />
        </div>
  );
};
// how to select different pages! neat element
export default PageSelect;
