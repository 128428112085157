import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from "@clerk/clerk-react";


const NotionAuthRedirect = () => {
  const navigate = useNavigate();
  const [hasFetched, setHasFetched] = useState(false);
  const [userReady, setUserReady] = useState(false); // New state to monitor when user is ready
  const { user, isLoaded } = useUser(); // isLoaded will indicate if the user data is ready

  useEffect(() => {
    // Monitor when user data is fully loaded and user exists
    if (isLoaded && user) {
      setUserReady(true); // Set userReady to true when user is loaded
    }
  }, [isLoaded, user]);

  useEffect(() => {
    if (!userReady || hasFetched) return;  // Wait until the user data is fully loaded and token hasn't been fetched yet

    const storeUserId = async (notionAuthValue: string) => {
        if (user) {
          try {
            const response = await fetch('http://localhost:5000/api/store-user-id', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ userId: user.id, notionAuthValue }),
            });
            const data = await response.json();
      
            if (!response.ok) {
              throw new Error(data.error || 'Failed to store user ID');
            }

          } catch (error) {
            throw new Error("Error inserting new user");
          }
        } else {
            throw new Error("No user found currently");
        }
      };

    const fetchToken = async () => {
      if (hasFetched) return;  // Prevent multiple submissions
      setHasFetched(true);

      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (!code) {
          throw new Error("Authorization code not found");
        }

        const response = await fetch(`http://localhost:5000/oauth/callback?code=${code}`);
        
        if (!response.ok) {
          throw new Error('Failed to exchange authorization code for access token');
        }

        const data = await response.json();
        console.log('Access token data:', data['access_token']);
        await storeUserId(data['access_token']);
        navigate('/');

      } catch (error) {
        console.error('Error during authorization:', error);
        navigate('/error');
      }
    };

    fetchToken();
  }, [hasFetched, navigate, user, userReady]); // Run when userReady changes

  return (
    <div>
      <div className="flex h-screen items-center justify-center bg-[#f9f9fd]">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <h1 className="text-2xl font-bold text-gray-700 mb-4">Authorizing...</h1>
        <p className="text-gray-500 mb-6">Please wait while we authorize your account with Notion.</p>
      </div>
    </div>
    </div>
  );
};

export default NotionAuthRedirect;
