import React from 'react';
import { Button } from './@/components/ui/button';
import { currently_generating } from './atoms';
import { useAtom } from 'jotai/react';

interface NotionAdderButtonProps {
    editorContent: string; // The current content from the editor
    notionAuth: string; // The auth code for the current user with their notion permissions
    selectedPageId: string; // id for current selected page to write to
    userId: string // user id for clerk
}

interface CalendarEvent {
    event_name: string;
    event_start_date: string;
    event_end_date: string;
    event_emoji: string;
    event_description: string;
}

const NotionAdderButton: React.FC<NotionAdderButtonProps> = ({ editorContent, notionAuth, selectedPageId, userId }) => {
    const [, setGeneratingState] = useAtom(currently_generating);

    const incrementUsageCount = async (editorContent: string) => {
            if (userId) {
                try {
                  const response = await fetch('http://localhost:5000/api/increment-usage', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId, editorContent }),
                  });
            
                  const result = await response.json();

                  if (response.ok) {
                    return result.data;
                  } else {
                    console.error('Error incrementing usage:', result.error);
                    return null;
                  }
                } catch (error) {
                  console.error('Error in increment usage count:', error);
                  return null;
                }
              } else {
                return null;
              }
      };


    const createDatabaseInNotion = async (): Promise<string | null> => {
        const parentPageId = selectedPageId;

        const databaseTitle = await fetchGPT4Title(editorContent);

        const requestBody = {
            notionAuth,
            parentPageId,
            databaseTitle,
            properties: {
                Name: {
                    title: {},
                },
                Date: {
                    date: {

                    }
                },
                Status: {
                    select: {
                        options: [
                            { name: '🐣 Not Started', color: 'red' },
                            { name: '🚧 In Progress', color: 'yellow' },
                            { name: '🎉 Completed', color: 'green' },
                        ],
                    },
                },
            },
        };

        try {
            const res = await fetch('http://localhost:5000/api/create-database', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            const data = await res.json();
            return data.databaseResponse.id;  // Return the ID so it can be used in the next chain
        } catch (error) {
            return null;
        }
    };

    const addPageToDatabase = async (databaseId: string, pageTitle: string, pageContent: string, pageStart: string, pageEnd: string, emoji: string): Promise<string> => {
        let requestBody
        if (pageEnd === "NONE" && pageStart !== "NONE")
        {
            requestBody = {
                notionAuth,
                databaseId,
                icon: {
                    type: 'emoji',
                    emoji: emoji,
                },
                pageProperties: {
                    Name: {
                        title: [
                            {
                                type: 'text',
                                text: {
                                    content: pageTitle,
                                },
                            },
                        ],
                    },
                    Status: {
                        select: {
                            name: '🐣 Not Started',
                        },
                    },
                    Date: {
                        date: {
                            start: pageStart,
                        },
                    },
                },
                children: [
                    {
                      object: 'block',
                      type: 'paragraph',
                      paragraph: {
                        rich_text: [
                          {
                            type: 'text',
                            text: {
                              content: pageContent,
                            },
                          },
                        ],
                      },
                    },
                  ],
            };
        }
        else if (pageStart === "NONE" || pageEnd === "NONE")
        {
            requestBody = {
                notionAuth,
                databaseId,
                icon: {
                    type: 'emoji',
                    emoji: emoji,
                },
                pageProperties: {
                    Name: {
                        title: [
                            {
                                type: 'text',
                                text: {
                                    content: pageTitle,
                                },
                            },
                        ],
                    },
                    Status: {
                        select: {
                            name: '🐣 Not Started',
                        },
                    },
                },
                children: [
                    {
                      object: 'block',
                      type: 'paragraph',
                      paragraph: {
                        rich_text: [
                          {
                            type: 'text',
                            text: {
                              content: pageContent,
                            },
                          },
                        ],
                      },
                    },
                  ],
            };
        }
        else
        {
        requestBody = {
            notionAuth,
            databaseId,
            icon: {
                type: 'emoji',
                emoji: emoji,
            },
            pageProperties: {
                Name: {
                    title: [
                        {
                            type: 'text',
                            text: {
                                content: pageTitle,
                            },
                        },
                    ],
                },
                Status: {
                    select: {
                        name: '🐣 Not Started',
                    },
                },
                Date: {
                    date: {
                        start: pageStart,
                        end: pageEnd,
                    },
                },
            },
            children: [
                {
                  object: 'block',
                  type: 'paragraph',
                  paragraph: {
                    rich_text: [
                      {
                        type: 'text',
                        text: {
                          content: pageContent,
                        },
                      },
                    ],
                  },
                },
              ],
        };
    }

        try {
            await fetch('http://localhost:5000/api/add-page', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
            return "Success"

        } catch (error) {
            return "Error"
        }
    };

    const createDatabaseAndAddPage = async () => {
        //let errorOccured = false
        setGeneratingState('generating')
        const newDatabaseId = await createDatabaseInNotion();
        if (newDatabaseId) { // this catches the newDatabaseId error case
            const structured_data = await fetchGPT4Content(editorContent);
            /*if (structured_data === "No response from GPT-4" || structured_data === "Error fetching content from GPT-4")
            {
                errorOccured = true
            }*/
            //console.log(structured_data)

            const titles: string[] = [];
            const startDates: string[] = [];
            const endDates: string[] = [];
            const emojis: string[] = [];
            const descriptions: string[] = [];

            structured_data.all_events.forEach((event: CalendarEvent) => {
                const title = event.event_name;
                const startDate = event.event_start_date;
                const endDate = event.event_end_date;
                const emoji = event.event_emoji;
                const description = event.event_description;
        
                titles.push(title);
                startDates.push(startDate);
                endDates.push(endDate);
                emojis.push(emoji);
                descriptions.push(description);
            });

            //const hasNonNoneValue = startDates.some(value => value !== "NONE");

            let all_results : string[] = []
            
            for (let i = titles.length - 1; i >= 0; i--) {
                const title = titles[i];
                const startDate = startDates[i];
                const endDate = endDates[i];
                const emoji = emojis[i];
                const description = descriptions[i];
                
                const result = await addPageToDatabase(newDatabaseId, title, description, startDate, endDate, emoji);
                all_results.push(result)
            }
            if (all_results.every(_ => _ === "Success"))
            {
                const result = incrementUsageCount(editorContent)
                if (result === null)
                {
                    setGeneratingState('error')
                }
                else
                {
                    setGeneratingState('success')
                }
            }   
        }
    };

    const fetchGPT4Content = async (prompt: string): Promise<any> => {
        try {
            const res = await fetch('http://localhost:5000/api/gpt-4', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content: prompt }),
            });

            const data = await res.json();
            return data || "No response from GPT-4"; // Adjust according to your API structure
        } catch (error) {
            return "Error fetching content from GPT-4";
        }
    };

    const fetchGPT4Title = async (prompt: string): Promise<string> => {
        try {
            const res = await fetch('http://localhost:5000/api/gpt-4-title', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ content: prompt }),
            });

            const data = await res.json();
            return data.response || "No response from GPT-4"; // Adjust according to your API structure
        } catch (error) {
            console.error('Error fetching GPT-4 content:', error);
            return "Error fetching content from GPT-4";
        }
    };

    return (
        <div className="flex justify-center items-center py-2 pb-4">
            <Button variant="timeline" onClick={createDatabaseAndAddPage}>
            Generate Timeline or Plan
            </Button>
        </div>
    );
}

export default NotionAdderButton;
