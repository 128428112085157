import React, { useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import { useSetAtom } from 'jotai/react';
import { editorContentAtom, pdfChangeAtom } from './atoms';
import { Button } from './@/components/ui/button';

pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.mjs';

const PDFUploadButton = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [, setFile] = useState<File | null>(null);
  const setEditorContent = useSetAtom(editorContentAtom);
  const setPdfChangeAtom = useSetAtom(pdfChangeAtom);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      extractPdfText(e.target.files[0])
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click(); // Programmatically click the hidden file input
  };

  const extractPdfText = async (file: File) => {
    if (!file) {
      console.log("No file selected");
      return;
    }

    try {
        const reader = new FileReader();
        reader.onload = async function (event) {
          const arrayBuffer = event.target?.result as ArrayBuffer;
      
          const typedArray = new Uint8Array(arrayBuffer);
          const pdf = await pdfjsLib.getDocument({ data: typedArray }).promise;
      
          let pdfText = '';
          for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const textContent = await page.getTextContent();
      
            // Use a regular `for` loop to iterate over textContent.items
            for (const item of textContent.items) {
              pdfText += (item as any).str + ' ';
            }
          }

        console.log("PDF Content: ", pdfText);
        setEditorContent(pdfText)
        setPdfChangeAtom(true)
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error reading PDF:", error);
    }
  };

  return (
    <div>
    <Button variant="timeline" onClick={handleButtonClick}>Upload PDF</Button>
    <input
        type="file"
        accept=".pdf"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }} // Hide the default input
      />
    </div>
  );
};

export default PDFUploadButton;
