import { Card, CardHeader, CardTitle, CardContent } from "./@/components/ui/card";
// would a 3rd feature ever make sense here? ask chatty g
// 3 things, 1 would it make sense to put paper summary in the same app? 2. should we gen timelines if they aren't present based on current dates (prob not, weekly schedule is meh quality)? 3. should we reuse some of this code and/or migrate to next/astro for this or the next project 

// Probably want to just have a video tutorial of the flow instead, look at the guy who made shipfast for examples
export const Features: React.FC = () => {
    return (
<section className="py-24 bg-[#f9f9fb]">  {/* Increased padding from py-16 to py-24 */}
  <div className="container mx-auto text-center">
    <h2 className="text-3xl font-bold mb-16">Features</h2>  {/* Adjusted spacing for more breathing room */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-12">  {/* Increased gap between cards */}
      <Card className="shadow-lg hover:shadow-xl transition-shadow">  {/* Added shadow for more depth */}
        <CardHeader>
          <CardTitle>Generate timelines for your pages</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Quickly add a course schedule or business plan and generate a timeline in Notion</p>
        </CardContent>
      </Card>
      <Card className="shadow-lg hover:shadow-xl transition-shadow">
        <CardHeader>
          <CardTitle>Break your problems into a plan</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Any content or notes you have can be broken and organized down into smaller chunks</p>
        </CardContent>
      </Card>
      <Card className="shadow-lg hover:shadow-xl transition-shadow">
        <CardHeader>
          <CardTitle>Keep your notes in Notion</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Keep all notes in the same place in Notion</p>
        </CardContent>
      </Card>
    </div>
  </div>
</section>
    );
};
