import React from "react";
import './app/globals.css';
import './index.css';
import { NavBar } from "./Navbar";
import { Footer } from "./Footer";
import { SignIn } from "@clerk/clerk-react";

const SignInPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <NavBar />
      <section className="flex-grow bg-[#f9f9fd] py-20">
        <div className="container mx-auto max-w-lg flex flex-col items-center">
          <h2 className="text-3xl font-bold text-center mb-8">Welcome Back!</h2>
          <SignIn path="/sign-in" routing="path" signUpUrl="/login" fallbackRedirectUrl="/" />
          <SignIn path="/login/sso-callback" routing="path" signUpUrl="/login" fallbackRedirectUrl="/" />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default SignInPage;
