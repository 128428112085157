import { $createParagraphNode, $createTextNode, $getRoot } from 'lexical';
import { useEffect } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { useAtomValue, useSetAtom } from 'jotai/react';
import { editorContentAtom, pdfChangeAtom } from './atoms';
import './editor_styles.css';

const theme = {
  // Theme styling goes here
  // ...
};

function onChange(editorState: any, setEditorContent: (content: string) => void) {
  editorState.read(() => {
    const root = $getRoot();
    setEditorContent(root.getTextContent());
  });
}

function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.focus();
  }, [editor]);

  return null;
}

function onError(error: any) {
  console.error(error);
}

interface LexicalEditorProps {
  setGetEditorText: (fn: () => string) => void;
}

function LexicalEditor({ setGetEditorText }: LexicalEditorProps) {
  const setEditorContent = useSetAtom(editorContentAtom);

  const initialConfig = {
    namespace: 'MyEditor',
    theme,
    onError,
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <PlainTextPlugin
        contentEditable={<ContentEditable className="editor-container" />}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <OnChangePlugin onChange={(editorState) => onChange(editorState, setEditorContent)} />
      <HistoryPlugin />
      <MyCustomAutoFocusPlugin />
      <SetGetEditorTextPlugin setGetEditorText={setGetEditorText} />
    </LexicalComposer>
  );
}

// Plugin to set the getEditorText function
function SetGetEditorTextPlugin({ setGetEditorText }: LexicalEditorProps) {
  const [editor] = useLexicalComposerContext();
  const editorContent = useAtomValue(editorContentAtom);
  const pdfChange = useAtomValue(pdfChangeAtom);
  const setPdfChange = useSetAtom(pdfChangeAtom);

  useEffect(() => {
    if (editorContent && pdfChange) {
      editor.update(() => {
        const root = $getRoot();
        root.clear(); // Clear current content
        const paragraphNode = $createParagraphNode();
        const textNode = $createTextNode(editorContent);

        paragraphNode.append(textNode);
        root.append(paragraphNode);
        setPdfChange(false)
      });
    }
  }, [pdfChange, setPdfChange, editorContent, editor]);

  useEffect(() => {
    const getEditorText = () => {
      let editorText = '';

      editor.getEditorState().read(() => {
        const root = $getRoot();
        editorText = root.getTextContent();
      });

      return editorText;
    };

    // Pass the getEditorText function directly to setGetEditorText
    setGetEditorText(getEditorText);
  }, [editor, setGetEditorText]);

  return null;
}

export default LexicalEditor;
