import React, { useEffect } from "react";
import './app/globals.css';
import './index.css';
import { NavBar } from "./Navbar";
import { Footer } from "./Footer";

const NotionAuth: React.FC = () => {

  useEffect(() => {
    const notionAuthUrl = `https://api.notion.com/v1/oauth/authorize?client_id=89f301e9-bd96-4024-8a27-88b8c18f7493&response_type=code&owner=user&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fredirect`;

    // Redirect user to the Notion authorization URL
    window.location.href = notionAuthUrl;
  }, []); // The empty dependency array ensures this runs once when the component mounts.

  return (
    <div className="flex flex-col min-h-screen">
      <NavBar />
      <section className="flex-grow bg-[#f9f9fd] py-20">
        <div className="container mx-auto max-w-lg flex flex-col items-center">
          <h2 className="text-3xl font-bold text-center mb-8">Redirecting to Notion...</h2>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default NotionAuth;
