import React, { useEffect } from "react";
import './app/globals.css';
import { NavBar } from "./Navbar";
import { Footer } from "./Footer";
import { Features } from "./Features";
import { Hero } from "./Hero";
import { useUser } from "@clerk/clerk-react";
import Pricing from "./Pricing";

const StripeCancelPage: React.FC = () => {
    const { user } = useUser();

  useEffect(() => {
    if (user) {
      console.log("User Information:", user);
    } else {
      console.log("No user is logged in.");
    }
  }, [user]);
  return (
    <div className="flex flex-col min-h-screen">
      <NavBar/>
      <Hero/>
      <Features/>
      <Pricing/>
      <Footer/>
    </div>
  );
};

export default StripeCancelPage;