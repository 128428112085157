import React, { useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import './app/globals.css';
import { NavBar } from "./Navbar";
import { Footer } from "./Footer";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./@/components/ui/select";
import { useCurrentMonthCount, useMaxMonthCount, useStripeSubscription } from "./hooks";

// Define the interface for NotionPage
interface NotionPage {
  title: any;
  id: string;
  parent: {
    type: string;
  };
  properties: {
    title: {
      title: {
        text: {
          content: string;
        };
      }[];
    };
  };
}

const ProfileInfo: React.FC = () => {
  const { user } = useUser();
  const [notionAuth, setNotionAuth] = useState('');
  const [, setUserId] = useState('');
  const [pages, setPages] = useState<NotionPage[]>([]);
  const monthUsage = useCurrentMonthCount(user)
  const monthMaxUsage = useMaxMonthCount(user)
  const stripeSubscription = useStripeSubscription(user)

  useEffect(() => {
    const retrieveUserNotionAuth = async () => {
      if (user) {
        try {
          const response = await fetch('http://localhost:5000/api/retrieve-user-notion-auth', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: user.id }),
          });

          const data = await response.json();

          if (!response.ok) {
            throw new Error(data.error || 'Failed to retrieve Notion auth');
          }

          return data;
        } catch (error) {
          console.error('Error retrieving Notion auth:', error);
          return null;
        }
      } else {
        return null;
      }
    };

    const fetchNotionAuth = async () => {
      if (user) {
        const notionAuth = await retrieveUserNotionAuth();
        setNotionAuth(notionAuth?.notion_auth || '');
        setUserId(user.id);
      }
    };

    fetchNotionAuth();
  }, [user]);

  useEffect(() => {
    const fetchPages = async () => {
      if (notionAuth) {
        try {
          const response = await fetch('http://localhost:5000/api/list-pages', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ notionToken: notionAuth }),
          });

          const data = await response.json();
          setPages(data.results || []);
        } catch (error) {
          console.error('Error fetching pages:', error);
        }
      }
    };

    fetchPages();
  }, [notionAuth]);

  return (
    <div className="flex flex-col min-h-screen bg-[#f9f9fd]">
      <NavBar />
      <div className="p-4 flex flex-col items-center">
        <h1 className="text-2xl font-bold mb-4">Profile Information</h1>
        
        <div className="w-full max-w-md">
          <h2 className="text-xl font-semibold mb-4">Generation Information</h2>
          <h3 className="pb-6">Generations used this month: {monthUsage}</h3>
          {stripeSubscription && monthMaxUsage && <h3 className="pb-6">Maximum generations allowed this month: {monthMaxUsage * 100}</h3>}
          {!stripeSubscription && <h3 className="pb-6">Maximum generations allowed this month: {monthMaxUsage}</h3>}
          </div>

        {/* Display Notion Pages */}
        <div className="w-full max-w-md">
          <h2 className="text-xl font-semibold mb-4">Accessible Notion Pages</h2>
          {pages.length > 0 ? (
            <Select>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Select a page" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Notion Workspace Pages</SelectLabel>
                  {pages.map((page, index) => (
                    page?.parent?.type === "workspace" && (
                      <SelectItem key={index} value={page.id}>
                        {page?.properties?.title?.title?.[0]?.text?.content || page?.title?.[0]?.text?.content}
                      </SelectItem>
                    )
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          ) : (
            <div className="flex items-center justify-center h-full space-x-2">
              <p className="text-gray-500">No pages found or loading...</p>
              {/* Optional loading spinner */}
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-amber-300"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* SVG paths */}
              </svg>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProfileInfo;
