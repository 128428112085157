import { useEffect, useState } from 'react';
export const useNotionAuth = (user: any) => {
    const [notionAuth, setNotionAuth] = useState<string | null>(null);
  
    useEffect(() => {
      const fetchNotionAuth = async () => {
        if (user) {
          try {
            const response = await fetch('http://localhost:5000/api/notion-auth', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ userId: user.id }),
            });
  
            const { notion_auth } = await response.json();
            setNotionAuth(notion_auth);
          } catch (error) {
            console.error('Error fetching notion auth:', error);
            setNotionAuth(null);
          }
        }
      };
  
      fetchNotionAuth();
    }, [user]);
  
    return notionAuth;
  };

  export const useCurrentMonthCount = (user: any) => {
    const [currentMonthCount, setCurrentMonthCount] = useState<number | null>(null);
  
    useEffect(() => {
      const fetchNotionCurrentMonth = async () => {
        if (user) {
          try {
            const response = await fetch('http://localhost:5000/api/notion-current-month', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ userId: user.id }),
            });
  
            const { current_usage } = await response.json();
            setCurrentMonthCount(current_usage);
          } catch (error) {
            console.error('Error fetching notion auth:', error);
            setCurrentMonthCount(null);
          }
        }
      };
  
      fetchNotionCurrentMonth();
    }, [user]);
  
    return currentMonthCount;
  };

  export const useMaxMonthCount = (user: any) => {
    const [maxMonthCount, setMaxMonthCount] = useState<number | null>(null);
  
    useEffect(() => {
      const fetchNotionMaxMonth = async () => {
        if (user) {
          try {
            const response = await fetch('http://localhost:5000/api/notion-max-month', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ userId: user.id }),
            });
  
            const { max_monthly_usage } = await response.json();
            setMaxMonthCount(max_monthly_usage);
          } catch (error) {
            console.error('Error fetching notion auth:', error);
            setMaxMonthCount(null);
          }
        }
      };
  
      fetchNotionMaxMonth();
    }, [user]);
  
    return maxMonthCount;
  };

  export const useStripeSubscription = (user: any) => {
    const [stripeSubscription, setStripeSubscription] = useState<string | null>(null);
  
    useEffect(() => {
      const fetchStripeSubscription = async () => {
        if (user) {
          try {
            const response = await fetch('http://localhost:5000/api/stripe-user-subscription', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ userId: user.id }),
            });
  
            const { stripe_subscription_id } = await response.json();
            setStripeSubscription(stripe_subscription_id);
          } catch (error) {
            console.error('Error fetching user auth:', error);
            setStripeSubscription(null);
          }
        }
      };
  
      fetchStripeSubscription();
    }, [user]);
  
    return stripeSubscription;
  };