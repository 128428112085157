import React from 'react';
import { Check } from 'lucide-react'; // Assuming you're using lucide-react for icons
import { Card } from './@/components/ui/card';
import { Button } from './@/components/ui/button';

interface PricingCardProps {
    title: string;
    price: string;
    features: string[];
    isHighlighted: boolean;
  }

const PricingCard: React.FC<PricingCardProps> = ({ title, price, features, isHighlighted }) => {
  return (
    <Card className={`p-6 ${isHighlighted ? 'bg-amber-500 text-white' : 'bg-white text-grey-900'} shadow-lg rounded-lg`}>
      <h3 className="text-2xl font-semibold">{title}</h3>
      <div className="mt-4">
        <span className="text-4xl font-bold">{price}</span>
        <span className="text-lg">/month</span>
      </div>
      <ul className="mt-6 space-y-4">
        {features.map((feature: string, index: number) => (
          <li key={index} className="flex items-center">
            <Check className="mr-2" />
            {feature}
          </li>
        ))}
      </ul>
      <Button
        className={`mt-6 w-full ${
            isHighlighted
            ? 'bg-white text-amber-500 hover:bg-gray-200 hover:text-amber-500'
            : 'bg-amber-500 text-white hover:bg-amber-600 hover:text-white'
        }`}
        >
        {isHighlighted ? 'Subscribe now' : 'Start now'}
        </Button>
    </Card>
  );
};

const Pricing = () => {
  return (
    <div className="flex flex-col justify-center items-center py-24 bg-[#f9f9fb]">
     <h2 className="text-3xl font-bold mb-16">Pricing</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <PricingCard // make sure this looks crisp and aligned well
          title="Basic"
          price="$0"
          features={[
            "5 timeline generations per month"
          ]}
          isHighlighted={false}
        />
        <PricingCard
          title="Pro"
          price="$15"
          features={[
            "5000 timeline generations per month"
          ]}
          isHighlighted={true}
        />
      </div>
    </div>
  );
};

export default Pricing;
