import React, { useRef } from 'react';
import * as mammoth from 'mammoth';
import { useSetAtom } from 'jotai/react';
import { editorContentAtom, pdfChangeAtom } from './atoms';
import { Button } from './@/components/ui/button';

const DocxUploadButton: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const setEditorContent = useSetAtom(editorContentAtom);
  const setPdfChangeAtom = useSetAtom(pdfChangeAtom);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const result = e.target?.result;
        if (result && result instanceof ArrayBuffer) {
          try {
            const mammothResult = await mammoth.extractRawText({ arrayBuffer: result });
            console.log(mammothResult.value);
            setEditorContent(mammothResult.value)
            setPdfChangeAtom(true)
          } catch (error) {
            console.error('Error reading .docx file:', error);
          }
        } else {
          console.error('Error: File could not be read as an ArrayBuffer');
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click(); // Programmatically click the hidden file input
  };

  return (
    <div>
      <Button variant="timeline" onClick={handleButtonClick}>Upload Docx</Button>
      <input
        type="file"
        accept=".docx"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default DocxUploadButton;
