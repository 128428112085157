import React from "react";
import './app/globals.css';
import './index.css';
import { NavBar } from "./Navbar";
import { Footer } from "./Footer";
import { SignUp } from "@clerk/clerk-react";

const RegistrationPage: React.FC = () => {

  return (
    <div className="flex flex-col min-h-screen">
      <NavBar />
      <section className="flex-grow bg-[#f9f9fd] py-20">
        <div className="container mx-auto max-w-lg flex flex-col items-center">
          <h2 className="text-3xl font-bold text-center mb-8">Create Your Account</h2>
          <SignUp // Figure out how to get this into the production version instead
            path="/login" 
            routing="path" 
            signInUrl="/sign-in"
          />
        </div>
      </section>
      <Footer />
    </div>
  );  
};

export default RegistrationPage;
  