import { useUser } from '@clerk/clerk-react'; // Assuming you are using Clerk for user authentication
import { Button } from './@/components/ui/button';

//const stripePublishableKey = 'pk_live_51Pt4rPRxbLJ4EHRTy8wiQIvRJ5h9SmRwHrsRHs6vQgt3YXWBO0vvNlbOERWt41kiUgXmvbe1rSQ8ropbsOy94e4R00xkPRFHUp';
//const stripePromise = loadStripe(stripePublishableKey);

const SubscribeButton = () => {
  const { user } = useUser(); // useUser is used inside the component

  const handleCheckout = async () => {
    //const stripe = await stripePromise;

    if (!user) {
      console.error("User not logged in");
      return;
    }

    const response = await fetch('http://localhost:5000/api/create-checkout-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: user.id }), // Pass the Clerk or Supabase user ID here
    });

    const { url } = await response.json();
    window.location.href = url; // Redirect to the Stripe checkout page
  };

  return (
    <Button variant= "login" onClick={handleCheckout}>Subscribe for $15/month</Button>
  );
};

export default SubscribeButton;